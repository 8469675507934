// 导入自己需要的组件
import {
    Image,
    Tabs,
    TabPane,
    Button,
    Pagination,
    ButtonGroup,
    Select,
    Option,
    Upload,
    Input,
    ColorPicker,
    InputNumber,
    Card,
    DatePicker,
    TimePicker,
    Dialog,
    Alert
} from 'element-ui'

const element = {
    install: function (Vue) {
        Vue.use(Image)
        Vue.use(Tabs);
        Vue.use(TabPane);
        Vue.use(Button);
        Vue.use(Pagination);
        Vue.use(ButtonGroup);
        Vue.use(Select);
        Vue.use(Option);
        Vue.use(Upload);
        Vue.use(Input);
        Vue.use(ColorPicker);
        Vue.use(InputNumber);
        Vue.use(Card)
        Vue.use(DatePicker)
        Vue.use(TimePicker)
        Vue.use(Dialog)
        Vue.use(Alert)
    }
}
export default element

