import Vue from "vue";

import {
  hasPermission,
  hasNoPermission,
  hasAnyPermission,
  hasRole,
  hasAnyRole,
} from "@/utils/permissionDirect";
import { dragModal } from "./direct-drag-modal";

const Plugins = [
  hasPermission,
  hasNoPermission,
  hasAnyPermission,
  hasRole,
  hasAnyRole,
  dragModal,
];

Plugins.map((plugin) => {
  Vue.use(plugin);
});

export default Vue;
