var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}}},[_c('a-tabs',{staticStyle:{"padding":"0 2px"},attrs:{"size":"large","tabBarStyle":{ textAlign: 'center', marginTop: '0px' },"activeKey":_vm.activeKey},on:{"change":_vm.handleTabsChange}},[_c('a-tab-pane',{key:"1",staticStyle:{"padding":"10px 0"},attrs:{"tab":"密码登录"}},[_c('a-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","closable":true,"message":_vm.error,"showIcon":""}}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator.trim",value:([
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入用户名称',
                    whitespace: true,
                  },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入用户名称',\n                    whitespace: true,\n                  },\n                ],\n              },\n            ]",modifiers:{"trim":true}}],attrs:{"size":"large","placeholder":"请输入用户名称"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator.trim",value:([
              'password',
              {
                rules: [
                  { required: true, message: '请输入密码', whitespace: true },
                ],
              },
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  { required: true, message: '请输入密码', whitespace: true },\n                ],\n              },\n            ]",modifiers:{"trim":true}}],attrs:{"size":"large","type":"password","placeholder":"请输入密码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',{staticStyle:{"margin":"0 -4px"},attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":16}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator.trim",value:([
                  'verifiCode',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入验证码',
                        whitespace: true,
                      },
                    ],
                  },
                ]),expression:"[\n                  'verifiCode',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '请输入验证码',\n                        whitespace: true,\n                      },\n                    ],\n                  },\n                ]",modifiers:{"trim":true}}],attrs:{"size":"large","placeholder":"请输入验证码","max-length":4}},[_c('a-icon',{attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"})],1)],1),_c('a-col',{staticStyle:{"padding-left":"4px"},attrs:{"span":8}},[_c('img',{staticStyle:{"width":"100%","height":"40px"},attrs:{"src":_vm.verifiImg,"alt":""},on:{"click":_vm.getVerifiCode}})])],1)],1)],1),_c('a-tab-pane',{key:"2",staticStyle:{"padding":"10px 0"},attrs:{"tab":"验证码登录"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号',
                    whitespace: true,
                  },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '请输入正确的手机号码',
                  },
                ],
              },
            ]),expression:"[\n              'mobile',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请输入手机号',\n                    whitespace: true,\n                  },\n                  {\n                    pattern: /^1[3456789]\\d{9}$/,\n                    message: '请输入正确的手机号码',\n                  },\n                ],\n              },\n            ]"}],attrs:{"size":"large","placeholder":"请输入手机号"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"mobile"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',{staticStyle:{"margin":"0 -4px"},attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":16}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'verifyCode',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入验证码',
                        whitespace: true,
                      },
                    ],
                  },
                ]),expression:"[\n                  'verifyCode',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '请输入验证码',\n                        whitespace: true,\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"size":"large","placeholder":"请输入验证码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-col',{staticStyle:{"padding-left":"4px"},attrs:{"span":8}},[(_vm.disabled)?_c('a-button',{staticClass:"captcha-button",staticStyle:{"width":"100%"},attrs:{"size":"large"},on:{"click":_vm.getCaptcha}},[_vm._v("获取验证码 ")]):_vm._e(),(!_vm.disabled)?_c('a-button',{staticClass:"captcha-button",staticStyle:{"width":"100%"},attrs:{"size":"large","disabled":""}},[_vm._v(_vm._s(_vm.vCodetimer)+" ")]):_vm._e()],1)],1)],1)],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","margin-top":"4px"},attrs:{"loading":_vm.loading,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v(" 登录 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }