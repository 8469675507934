import axios from "axios";
import {message, Modal, notification} from "ant-design-vue";
import moment from "moment";
import store from "../store";
import db from "@/utils/localstorage";

moment.locale("zh-cn");

// 统一配置
let FEBS_REQUEST = axios.create({
    baseURL: "/",
    responseType: "json",
    validateStatus(status) {
        // 200 外的状态码都认定为失败
        return status === 200;
    },
});

// 拦截请求
FEBS_REQUEST.interceptors.request.use(
    (config) => {
        let expireTime = store.state.account.expireTime;
        let now = moment().format("YYYYMMDDHHmmss");
        if (config.url.indexOf("actuator") < 0) {
            config.url = "admin/" + config.url;
        }

        // 让token早10秒种过期，提升“请重新登录”弹窗体验
        if (now - expireTime >= -10) {
            message.warning("很抱歉，登录已过期，请重新登录");
            // Modal.error({
            //   title: '登录已过期',
            //   content: '很抱歉，登录已过期，请重新登录',
            //   okText: '重新登录',
            //   mask: false,
            //   onOk: () => {
            return new Promise((resolve, reject) => {
                db.clear();
                location.reload();
            });
            // }
            // })
        }
        // 有 token就带上
        if (store.state.account.token) {
            config.headers.Authentication = store.state.account.token;
        }
        config.headers.clientType = "pc_console";

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 拦截响应
FEBS_REQUEST.interceptors.response.use(
    (res) => {
        if (res.config.url.indexOf("actuator") >= 0) {
            return res;
        }
        if (res.config.responseType === "blob") {
            return res;
        }
        const data = res.data || {};
        if (data.code === 0 || typeof data == "string") {
            return data;
        } else {
            let errorMessage = data.message || "";
            if (
                errorMessage.indexOf("未登录") >= 0 ||
                errorMessage.indexOf("没有登录") >= 0 ||
                errorMessage.indexOf("登录失效") >= 0 ||
                errorMessage.indexOf("重新登录") >= 0
            ) {
                return new Promise((resolve, reject) => {
                    db.clear();
                    location.reload();
                });
            } else {
                notification.error({
                    message: "系统提示",
                    description: data.message || "系统异常",
                    duration: 4,
                });
                return Promise.reject(res);
            }
        }
    },
    (error) => {
        if (error.response) {
            let errorMessage = !error.response.data
                ? "系统内部异常，请联系网站管理员"
                : error.response.data.message;
            if (
                errorMessage.indexOf("未登录") >= 0 ||
                errorMessage.indexOf("没有登录") >= 0 ||
                errorMessage.indexOf("登录失效") >= 0 ||
                errorMessage.indexOf("重新登录") >= 0
            ) {
                return new Promise((resolve, reject) => {
                    db.clear();
                    location.reload();
                });
            }
            switch (error.response.status) {
                case 404:
                    notification.error({
                        message: "系统提示",
                        description: "很抱歉，资源未找到",
                        duration: 4,
                    });
                    break;
                case 403:
                case 401:
                    notification.warn({
                        message: "系统提示",
                        description:
                            "很抱歉，您无法访问该资源，可能是因为没有相应权限或者登录已失效",
                        duration: 4,
                    });
                    break;
                default:
                    notification.error({
                        message: "系统提示",
                        description: errorMessage,
                        duration: 4,
                    });
                    break;
            }
        }
        return Promise.reject(error);
    }
);

const request = {
    post(url, params) {
        return FEBS_REQUEST.post(url, params, {
            transformRequest: [
                (params) => {
                    let result = "";
                    Object.keys(params || {}).forEach((key) => {
                        if (
                            !Object.is(params[key], undefined) &&
                            !Object.is(params[key], null)
                        ) {
                            result +=
                                encodeURIComponent(key) +
                                "=" +
                                encodeURIComponent(params[key]) +
                                "&";
                        }
                    });
                    return result;
                },
            ],
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },
    put(url, params) {
        return FEBS_REQUEST.put(url, params, {
            transformRequest: [
                (params) => {
                    let result = "";
                    Object.keys(params || {}).forEach((key) => {
                        if (
                            !Object.is(params[key], undefined) &&
                            !Object.is(params[key], null) &&
                            params[key] !== "" &&
                            params[key] !== "undefined"
                        ) {
                            result +=
                                encodeURIComponent(key) +
                                "=" +
                                encodeURIComponent(params[key]) +
                                "&";
                        }
                    });
                    return result;
                },
            ],
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },

    postJson(url, params) {
        return FEBS_REQUEST.post(url, params, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    get(url, params) {
        let _params;
        if (Object.is(params, undefined)) {
            _params = "";
        } else {
            _params = "?";
            for (let key in params || {}) {
                if (
                    params.hasOwnProperty(key) &&
                    params[key] !== null &&
                    params[key] !== "" &&
                    params[key] !== "undefined" &&
                    params[key] !== undefined
                ) {
                    _params += `${key}=${params[key]}&`;
                }
            }
        }
        return FEBS_REQUEST.get(`${url}${_params}`);
    },
    getBlob(url, params) {
        let _params;
        if (Object.is(params, undefined)) {
            _params = "";
        } else {
            _params = "?";
            for (let key in params || {}) {
                if (
                    params.hasOwnProperty(key) &&
                    params[key] !== null &&
                    params[key] !== "" &&
                    params[key] !== "undefined" &&
                    params[key] !== undefined
                ) {
                    _params += `${key}=${params[key]}&`;
                }
            }
        }
        return FEBS_REQUEST.get(`${url}${_params}`, {responseType: "blob"}).then(
            (res) => {
                let blob = new Blob([res.data]);
                let url = window.URL.createObjectURL(blob);
                return url;
            }
        );
    },
    delete(url, params) {
        let _params;
        if (Object.is(params, undefined)) {
            _params = "";
        } else {
            _params = "?";
            for (let key in params) {
                if (
                    params.hasOwnProperty(key) &&
                    params[key] !== null &&
                    params[key] !== "" &&
                    params[key] !== "undefined" &&
                    params[key] !== undefined
                ) {
                    _params += `${key}=${params[key]}&`;
                }
            }
        }
        return FEBS_REQUEST.delete(`${url}${_params}`);
    },
    export(url, params = {}, fileName) {
        message.loading("导出数据中");
        return FEBS_REQUEST.post(url, params, {
            transformRequest: [
                (params) => {
                    let result = "";
                    Object.keys(params).forEach((key) => {
                        if (
                            !Object.is(params[key], undefined) &&
                            !Object.is(params[key], null) &&
                            params[key] !== "" &&
                            params[key] !== "undefined"
                        ) {
                            result +=
                                encodeURIComponent(key) +
                                "=" +
                                encodeURIComponent(params[key]) +
                                "&";
                        }
                    });
                    return result;
                },
            ],
            responseType: "blob",
        })
            .then((r) => {
                const content = r.data;
                const blob = new Blob([content]);

                fileName = (fileName || `${new Date().getTime()}_导出结果`) + ".xlsx";

                if ("download" in document.createElement("a")) {
                    const elink = document.createElement("a");
                    elink.download = fileName;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href);
                    document.body.removeChild(elink);
                } else {
                    navigator.msSaveBlob(blob, fileName);
                }
            })
            .catch((r) => {
                console.error(r);
                message.error("导出失败");
            });
    },
    download(url, params, filename) {
        message.loading("文件传输中");
        return FEBS_REQUEST.post(url, params, {
            transformRequest: [
                (params) => {
                    let result = "";
                    Object.keys(params).forEach((key) => {
                        if (
                            !Object.is(params[key], undefined) &&
                            !Object.is(params[key], null) &&
                            params[key] !== "" &&
                            params[key] !== "undefined"
                        ) {
                            result +=
                                encodeURIComponent(key) +
                                "=" +
                                encodeURIComponent(params[key]) +
                                "&";
                        }
                    });
                    return result;
                },
            ],
            responseType: "blob",
        })
            .then((r) => {
                const content = r.data;
                const blob = new Blob([content]);
                if ("download" in document.createElement("a")) {
                    const elink = document.createElement("a");
                    elink.download = filename;
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href);
                    document.body.removeChild(elink);
                } else {
                    navigator.msSaveBlob(blob, filename);
                }
            })
            .catch((r) => {
                console.error(r);
                message.error("下载失败");
            });
    },
    upload(url, params) {
        return FEBS_REQUEST.post(url, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
};

export default request;
