<template>
  <a-layout-header
      :class="[fixHeader && 'ant-header-fixedHeader', layout === 'side' ? (sidebarOpened ? 'ant-header-side-opened' : 'ant-header-side-closed') : null, theme, 'global-header' ]"
      :style="isMobile?'padding-left:70px':''"
  >
    <div :class="['global-header-wide', layout]">
      <a-divider v-if="isMobile" type="vertical"/>
      <a-icon v-if="!isMobile && layout === 'side'" class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="toggleCollapse"/>
      <span v-show="isMobile||collapsed" class="collapsed-name ant-menu-item-selected">{{ systemName }}</span>
      <div v-if="layout === 'head'" class="global-header-menu">
        <i-menu style="height: 64px; line-height: 64px;" class="system-top-menu" :theme="theme" mode="horizontal"
                :menuData="menuData" @select="onSelect"/>
      </div>

      <div :class="['global-header-right', theme]">
        <a-dropdown style="margin-right:15px;" v-if="currentUser.memberSystem&&currentUser.memberSystem.inviteCode">
          <a-icon type="qrcode" style="font-size: 25px;"/>
          <a-menu slot="overlay" style="margin-top:20px;">
            <a-menu-item>
              <div class="inviteQrcode">
                <p>我的专属邀请码：<b style="color:red">{{ currentUser.memberSystem.inviteCode }}</b></p>
                <img class="img_qrcode" :src="inviteQrCode|fullImgPath"></img>
                <p>打开微信<b style="color:red">【扫一扫】</b></p>
                <p><small>在二维码上点击鼠标右键后，<br>再点击【图片另存为】下载！</small></p>
              </div>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-dropdown style="margin-right:15px;">
          <a-badge :count="todoCount.waitAuditOrderCount + todoCount.waitAuditSiteCount">
            <a-icon type="bell" style="font-size: 25px;"/>
          </a-badge>
          <a-menu slot="overlay" style="margin-top:20px;">
            <a-menu-item v-for="item in todoList" :key="item.name" style="padding-right:20px;"
                         @click="gotoUrl(item.url)">
              <a-avatar style="  color: #42b983;background-color: #d6f8b8;">{{ item.avatar }}</a-avatar>
              <span v-html="item.des "></span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <header-avatar class="header-item"/>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import HeaderAvatar from './HeaderAvatar'
import IMenu from '@/components/menu/menu'
import {mapState, mapMutations} from 'vuex'

export default {
  name: 'GlobalHeader',
  components: {IMenu, HeaderAvatar},
  props: ['collapsed', 'menuData'],
  computed: {
    ...mapState({
      isMobile: state => state.setting.isMobile,
      layout: state => state.setting.layout,
      systemName: state => state.setting.systemName,
      sidebarOpened: state => state.setting.sidebar.opened,
      fixHeader: state => state.setting.fixHeader,
      todoCount: state => state.account.todoCount,
      currentUser: state => state.account.user
    }),
    theme () {
      return this.layout === 'side' ? 'light' : this.$store.state.setting.theme
    },
    inviteQrCode () {
      if (this.currentUser && this.currentUser.memberSystem && this.currentUser.memberSystem.inviteQrcode) {
        return this.currentUser.memberSystem.inviteQrcode
      } else {
        return '/static/img/daida_qrcode.jpg'
      }
    },
    todoList () {
      let list = [
        {
          name: '订单审核',
          des: ' 待审核订单数 - <b class="red">' + this.todoCount.waitAuditOrderCount + '</b>',
          avatar: '订',
          url: {path: '/order/orderList', query: {status: 5}}
        },
        {
          name: '广告点审核',
          des: ' 待审核广告点数 - <b class="red">' + this.todoCount.waitAuditSiteCount + '</b>',
          avatar: '点',
          url: {path: '/base/site/self', query: {status: 5}}
        }
      ]
      // if (this.todoCount && this.todoCount.toAuditOrderCount > 0
      //     && this.$helper.hasPermission('order:audit')) {
        // list.push({
        //   name: '订单审核',
        //   des: ' 待审核订单数 - <b class="red">' + this.todoCount.toAuditOrderCount + '</b>',
        //   avatar: '订',
        //   url: {path: '/order/orderList', query: {status: 5}}
        // })
      // }
      // if (this.todoCount && this.todoCount.toAuditOrderCount > 0
      //     && this.$helper.hasPermission('exchange:audit')) {
      //   list.push({
      //     name: '提现审核',
      //     des: ' 待审核提现数 - <b class="red">' + this.todoCount.toAuditExchangeCount + '</b>',
      //     avatar: '提',
      //     url: {path: '/account/exchange', query: {status: 0}}
      //   })
      // }
      return list
    }
  },
  mounted () {
    // this.queryTodoCount()
    // setInterval(this.queryTodoCount, 60 * 1000)
  },
  methods: {
    ...mapMutations({
      setTodoCount (commit, val) {
        commit('account/setTodoCount', val)
      }
    }),
    toggleCollapse () {
      this.$emit('toggleCollapse')
    },
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    openAlert () {
      if (this.$helper.hasPermission('order:audit')) {
        this.$router.push({path: '/order/orderList', query: {status: 5}})
      }
      this.$message.info('打开提示信息')
    },
    queryTodoCount () {
      this.$get(`index/todoCount`).then((r) => {
        this.setTodoCount(r.data)
      })
    },
    gotoUrl (url) {
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
.trigger {
  font-size: 20px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.header-item {
  padding: 0 19px;
  display: inline-block;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;

  i {
    font-size: 16px;
    color: rgba(0, 0, 0, .65);
  }
}

.global-header {
  padding: 0 12px 0 0;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  position: relative;

  &.light {
    background: #fff;
  }

  &.dark {
    background: #393e46;
  }

  .global-header-wide {
    &.head {
      padding: 0 24px;
    }

    &.side {
    }

    .logo {
      height: 64px;
      line-height: 58px;
      vertical-align: top;
      display: inline-block;
      padding: 0 12px 0 24px;
      cursor: pointer;
      font-size: 20px;

      &.pc {
        padding: 0 12px 0 0;
      }

      img {
        display: inline-block;
        vertical-align: middle;
      }

      h1 {
        display: inline-block;
        font-size: 16px;
      }

      &.dark h1 {
        color: #fff;
      }
    }

    .global-header-menu {
      display: inline-block;
    }

    .global-header-right {
      float: right;

      &.dark {
        color: #fff;

        i {
          color: #fff;
        }
      }
    }
  }
}

/deep/ .inviteQrcode {
  margin-top: 10px;
  border: 1px solid #CCC;
  padding: 10px 20px;
  z-index: 1000;
  background: #FFF;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;

  p {
    text-align: center;

    &:first-child {
      padding-top: 10px;
    }
  }

  .img_qrcode {
    width: 200px;
    height: 200px;
  }
}

.ant-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 15;
  width: 100%;
  transition: width .2s;

  &.ant-header-side-opened {
    width: 100%;
    padding-left: 254px;
  }

  &.ant-header-side-closed {
    width: 100%;
    padding-left: 80px;
  }
}

.collapsed-name {
  /*color: #1890ff;*/
  font-size: 20px;
  margin: -10px 0 0 -15px;
  font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 600;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
}

.mobile .collapsed-name {
  margin: -10px 0 0 10px;
}
</style>
