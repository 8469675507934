var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-layout-register"},[_c('a-form',{ref:"formRegister",attrs:{"autoFormCreate":(form) => {
          this.form = form;
        },"id":"formRegister"}},[_c('a-form-item',{attrs:{"fieldDecoratorId":"email","fieldDecoratorOptions":{
          rules: [
            { required: true, message: '请输入注册账号' },
            { validator: this.handleUsernameCheck },
          ],
          validateTrigger: ['change', 'blur'],
        }}},[_c('a-input',{attrs:{"size":"large","type":"text","placeholder":"账号"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('a-popover',{attrs:{"placement":"rightTop","trigger":"click","visible":_vm.state.passwordLevelChecked}},[_c('template',{slot:"content"},[_c('div',{style:({ width: '240px' })},[_c('div',{class:['user-register', _vm.passwordLevelClass]},[_vm._v(" 强度："),_c('span',[_vm._v(_vm._s(_vm.passwordLevelName))])]),_c('a-progress',{attrs:{"percent":_vm.state.percent,"showInfo":false,"strokeColor":_vm.passwordLevelColor}}),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v("请至少输入 6 个字符。请不要使用容易被猜到的密码。")])])],1)]),_c('a-form-item',{attrs:{"fieldDecoratorId":"password","fieldDecoratorOptions":{
            rules: [
              { required: true, message: '至少6位密码' },
              { validator: this.handlePasswordLevel },
            ],
            validateTrigger: ['change', 'blur'],
          }}},[_c('a-input',{attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"至少6位密码"},on:{"click":_vm.handlePasswordInputClick},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],2),_c('a-form-item',{attrs:{"fieldDecoratorId":"password2","fieldDecoratorOptions":{
          rules: [
            { required: true, message: '至少6位密码' },
            { validator: this.handlePasswordCheck },
          ],
          validateTrigger: ['change', 'blur'],
        }}},[_c('a-input',{attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"确认密码"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"register-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.registerBtn,"disabled":_vm.registerBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("立即注册 ")]),_c('a',{staticClass:"login",on:{"click":_vm.returnLogin}},[_vm._v("使用已有账户登录")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }