import Vue from "vue";
import App from "./App";
import router from "./router";
import Antd from "ant-design-vue";
import store from "./store";
import request from "@/utils/request";
import db from "@/utils/localstorage";
import VueApexCharts from "vue-apexcharts";
import helper from "@/utils/helper";
import "ant-design-vue/dist/antd.css";

import "/static/less/Common.less";
import "@/utils/install";
import filter from "@/utils/filter";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import {DICT} from "@/utils/dict.js";

//样式文件，需单独引入
import "element-ui/lib/theme-chalk/index.css";
//引入写有需要的组件的文件
import element from "./element/index";
Vue.use(element);

Vue.prototype.$bus = new Vue({}); //创建一个 vue 实例，将 $bus 注册到 vue 的 prototype 上

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(db);
Vue.use(VueApexCharts);
Vue.use(filter);
Vue.component("apexchart", VueApexCharts);
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
  },
});
Vue.use({
  install(Vue) {
    Vue.prototype.$db = db;
  },
});

Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$getBlob = request.getBlob;
Vue.prototype.$put = request.put;
Vue.prototype.$delete = request.delete;
Vue.prototype.$export = request.export;
Vue.prototype.$download = request.download;
Vue.prototype.$upload = request.upload;
Vue.prototype.$postJson = request.postJson;
Vue.prototype.$helper = helper;
Vue.prototype.$DICT = DICT;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
