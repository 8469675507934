<template>
  <div
      :class="[
      multipage === true ? 'multi-page' : 'single-page',
      'not-menu-page',
      'home-page',
    ]"
  >
    <a-row :gutter="8" class="head-info">
      <a-card class="head-info-card">
        <a-col :span="12">
          <div class="head-info-avatar">
            <img alt="头像" :src="avatar"/>
          </div>
          <div class="head-info-count">
            <div class="head-info-welcome">
              {{ welcomeMessage }}
            </div>
            <div class="head-info-desc">
              <p>
                {{ user.deptName ? user.deptName : "暂无部门" }} |
                {{ user.roleName ? user.roleName : "暂无角色" }}
              </p>
            </div>
            <div class="head-info-time">
              上次登录时间：{{
                user.lastLoginTime ? user.lastLoginTime : "第一次访问系统"
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div>
            <a-row class="more-info">
              <a-col :span="4">
                <head-info
                    title="字节昨增"
                    :content="statisticData.adFansToutiaoYesterdayCount || 0"
                    :center="true"
                    :bordered="false"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                    title="字节今增"
                    :content="statisticData.adFansToutiaoTodayCount || 0"
                    :center="true"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                    title="字节总数"
                    :content="statisticData.adFansToutiaoCount || 0"
                    :center="true"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                    title="微信昨增"
                    :content="statisticData.adFansYesterdayCount || 0"
                    :center="true"
                    :bordered="false"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                    title="微信今增"
                    :content="statisticData.adFansTodayCount || 0"
                    :center="true"
                    :bordered="false"
                />
              </a-col>
              <a-col :span="4">
                <head-info
                    title="总人数"
                    :content="statisticData.adFansTotalCount"
                    :center="true"
                    :bordered="false"
                />
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-card>
    </a-row>
    <a-row :gutter="8" class="count-info">
      <a-col :span="12" class="visit-count-wrapper">
        <div>
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="用户新增记录">
              <a-card class="visit-count">
                <apexchart
                    ref="fansCount"
                    type="bar"
                    height="300"
                    :options="fansCountOptions"
                    :series="fansCountSeries"
                />
              </a-card>
            </a-tab-pane>
            <a-tab-pane key="2" tab="用户总数记录" force-render>
              <a-card class="visit-count">
                <apexchart
                    ref="fansTotalCount"
                    type="bar"
                    height="300"
                    :options="fansTotalCountOptions"
                    :series="fansTotalCountSeries"
                />
              </a-card>
            </a-tab-pane>
            <a-tab-pane key="3" tab="帖子新增记录" force-render>
              <a-card class="visit-count">
                <apexchart
                    ref="postCount"
                    type="bar"
                    height="300"
                    :options="postCountOptions"
                    :series="postCountSeries"
                />
              </a-card>
            </a-tab-pane>
          </a-tabs>
        </div>

      </a-col>
      <a-col
          :span="12"
          class="project-wrapper"
      >
        <a-card title="待处理项目" class="project-card">
          <a slot="extra"> 共{{ todoList.length }}项 </a>
          <table class="todo-table">
            <tr v-for="item in todoList" :key="item.text">
              <td style="cursor: pointer">
                <a :href="item.url" style="cursor: pointer">
                  <div class="project-detail">
                    <div class="project-name">
                      {{ item.text }}
                    </div>
                    <div class="project-desc">
                      {{ item.count }}
                    </div>
                  </div>
                </a>
              </td>
            </tr>
          </table>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import HeadInfo from "@/views/common/HeadInfo";
import {mapState} from "vuex";
import moment from "moment";

moment.locale("zh-cn");

const CHART_OPTIONS = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [],
  },
  fill: {
    opacity: 1,
  },
};

export default {
  name: "HomePage",
  components: {HeadInfo},
  data() {
    return {
      series: [],
      chartOptions: Object.assign({}, CHART_OPTIONS),
      fansCountOptions: Object.assign({}, CHART_OPTIONS),
      fansTotalCountOptions: Object.assign({}, CHART_OPTIONS),
      fansCountSeries: [],
      fansTotalCountSeries: [],
      postCountOptions: Object.assign({}, CHART_OPTIONS),
      postCountSeries: [],

      // projects: [
      //   // {
      //   //   name: '订单审核',
      //   //   des: '【xxxx】提交新订单，请尽快审核。',
      //   //   avatar: '订'
      //   // },
      //
      // ],
      statisticData: {},
      todayIp: "",
      todayVisitCount: "",
      totalVisitCount: "",
      todoList: [],
      userRole: "",
      userDept: "",
      lastLoginTime: "",
      welcomeMessage: "",
    };
  },
  computed: {
    ...mapState({
      multipage: (state) => state.setting.multipage,
      user: (state) => state.account.user || {},
    }),
    avatar() {
      return `static/avatar/${this.user.avatar}`;
    },
  },
  watch: {
    // "$store.state.account.todoCount"(newVal) {
    //   this.todoCount = newVal;
    // },
  },

  mounted() {
    this.welcomeMessage = this.welcome();
    this.$get(`index`)
        .then((r) => {
          let data = r.data;
          this.statisticData = data || {};
          this.todayIp = data.todayIp;
          this.todayVisitCount = data.todayVisitCount;
          this.totalVisitCount = data.totalVisitCount;
          this.todoList = data.todoList || [];

          // let sevenVisitCount = [];
          // let dateArr = [];
          // for (let i = 6; i >= 0; i--) {
          //   let time = moment().subtract(i, "days").format("MM-DD");
          //   let contain = false;
          //   for (let o of data.lastSevenVisitCount) {
          //     if (o.days === time) {
          //       contain = true;
          //       sevenVisitCount.push(o.count);
          //     }
          //   }
          //   if (!contain) {
          //     sevenVisitCount.push(0);
          //   }
          //   dateArr.push(time);
          // }
          // let sevenUserVistCount = [];
          // for (let i = 6; i >= 0; i--) {
          //   let time = moment().subtract(i, "days").format("MM-DD");
          //   let contain = false;
          //   for (let o of data.lastSevenUserVisitCount) {
          //     if (o.days === time) {
          //       contain = true;
          //       sevenUserVistCount.push(o.count);
          //     }
          //   }
          //   if (!contain) {
          //     sevenUserVistCount.push(0);
          //   }
          // }
          // this.$refs.count.updateSeries(
          //     [
          //       {
          //         name: "您",
          //         data: sevenUserVistCount,
          //       },
          //       {
          //         name: "总数",
          //         data: sevenVisitCount,
          //       },
          //     ],
          //     true
          // );
          // this.$refs.count.updateOptions(
          //     {
          //       xaxis: {
          //         categories: dateArr,
          //       },
          //       title: {
          //         text: "近七日系统访问记录",
          //         align: "left",
          //       },
          //     },
          //     true,
          //     true
          // );
        })
        .catch((r) => {
          console.error(r);
          this.$message.error("获取首页信息失败");
        });
    this.loadFansCountReport();
  },
  methods: {
    welcome() {
      const date = new Date();
      const hour = date.getHours();
      let time =
          hour < 6
              ? "早上好"
              : hour <= 11
                  ? "上午好"
                  : hour <= 13
                      ? "中午好"
                      : hour <= 18
                          ? "下午好"
                          : "晚上好";
      let welcomeArr = [
        "喝杯咖啡休息下吧☕",
        "要不要和朋友打局LOL",
        "要不要和朋友打局王者荣耀",
        "几天没见又更好看了呢😍",
        "今天又写了几个Bug🐞呢",
        "今天在群里吹水了吗",
        "今天吃了什么好吃的呢",
        "今天您微笑了吗😊",
        "今天帮助别人解决问题了吗",
        "准备吃些什么呢",
        "周末要不要去看电影？",
      ];
      let index = Math.floor(Math.random() * welcomeArr.length);
      return `${time}，${welcomeArr[index]}`;
    },
    loadFansCountReport() {
      let startDate = moment().add(-7, "day").format("YYYY-MM-DD");

      this.$get("fansRpt/listByDate", {startDate}).then((res) => {
        let data = res.data || [];
        let douyinNewCount = [], weixNewCount = [], newCount = [];
        let dateArr = [];
        for (let i = 0; i < data.length; i++) {
          douyinNewCount.push(data[i].douyinNew);
          weixNewCount.push(data[i].weixinNew);
          newCount.push(data[i].newCount);
          dateArr.push(moment(data[i].curDate).format("MM-DD"));
        }

        this.$refs.fansCount.updateSeries(
            [
              {
                name: "字节新增",
                data: douyinNewCount,
              },
              {
                name: "微信新增",
                data: weixNewCount,
              },
              {
                name: "合计新增",
                data: newCount,
              },
            ],
            true
        );

        this.$refs.fansCount.updateOptions(
            {
              xaxis: {
                categories: dateArr,
              },
              title1: {
                text: "用户新增记录",
                align: "left",
              },
            },
            true,
            true
        );

        //收益
        let douyinTotalCount = [],
            weixinTotalCount = [],
            totalCount = [];
        let dateArr2 = [];
        for (let i = 0; i < data.length; i++) {
          douyinTotalCount.push(data[i].douyinTotal);
          weixinTotalCount.push(data[i].weixinTotal);
          totalCount.push(data[i].totalCount);
          dateArr2.push(moment(data[i].curDate).format("MM-DD"));
        }
        this.$refs.fansTotalCount.updateSeries(
            [
              {
                name: "字节总数",
                data: douyinTotalCount,
              },
              {
                name: "微信总数",
                data: weixinTotalCount,
              },
              {
                name: "合计总数",
                data: totalCount,
              },
            ],
            true
        );

        this.$refs.fansTotalCount.updateOptions(
            {
              xaxis: {
                categories: dateArr2,
              },
              title1: {
                text: "用户总数记录",
                align: "left",
              },
            },
            true,
            true
        );

        //帖子数
        let postNewCount = [], postTotalCount = [];
        let postDateArr = [];
        for (let i = 0; i < data.length; i++) {
          postNewCount.push(data[i].newPost||0);
          // postTotalCount.push(Number((data[i].totalPost||0)/100.0).toFixed(2));
          postTotalCount.push(data[i].totalPost||0);
          postDateArr.push(moment(data[i].curDate).format("MM-DD"));
        }

        this.$refs.postCount.updateSeries(
            [
              {
                name: "今日新增",
                data: postNewCount,
              },
              // {
              //   name: "帖子总数",
              //   data: postTotalCount,
              // }
            ],
            true
        );

        this.$refs.postCount.updateOptions(
            {
              stroke: { curve: 'straight' },
              xaxis: {
                categories: postDateArr,
              },
              title1: {
                text: "帖子新增记录",
                align: "left",
              },
            },
            true,
            true
        );
      });
    },
  },
};
</script>
<style lang="less">
.home-page {
  .head-info {
    margin-bottom: 0.5rem;

    .head-info-card {
      padding: 0.5rem;
      border-color: #f1f1f1;

      .head-info-avatar {
        display: inline-block;
        float: left;
        margin-right: 1rem;

        img {
          width: 5rem;
          border-radius: 2px;
        }
      }

      .head-info-count {
        display: inline-block;
        float: left;

        .head-info-welcome {
          font-size: 1.05rem;
          margin-bottom: 0.1rem;
        }

        .head-info-desc {
          color: rgba(0, 0, 0, 0.45);
          font-size: 0.8rem;
          padding: 0.2rem 0;

          p {
            margin-bottom: 0;
          }
        }

        .head-info-time {
          color: rgba(0, 0, 0, 0.45);
          font-size: 0.8rem;
          padding: 0.2rem 0;
        }
      }
    }
  }

  .count-info {
    .visit-count-wrapper {
      padding-left: 0 !important;

      .visit-count {
        padding: 0.5rem;
        border-color: #f1f1f1;

        .ant-card-body {
          padding: 0.5rem 1rem !important;
        }
      }
    }

    .project-wrapper {
      padding-right: 0 !important;

      .project-card {
        border: none !important;

        .ant-card-head {
          border-left: 1px solid #f1f1f1 !important;
          border-top: 1px solid #f1f1f1 !important;
          border-right: 1px solid #f1f1f1 !important;
        }

        .ant-card-body {
          padding: 0 !important;

          table {
            width: 100%;

            td {
              width: 50%;
              border: 1px solid #f1f1f1;
              padding: 0.6rem;

              .project-avatar-wrapper {
                display: inline-block;
                float: left;
                margin-right: 0.7rem;

                .project-avatar {
                  color: #42b983;
                  background-color: #d6f8b8;
                }
              }
            }
          }
        }

        .project-detail {
          display: flex;
          align-items: center;
          width: 78%;
          margin-left: 20px;

          .project-name {
            font-size: 0.9rem;
            margin-top: -2px;
          }

          .project-desc {
            color: #ff0000;
            margin-left: 10px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .todo-table {
    tr td:hover {
      background: #e6f7ff;
    }
  }
}
</style>
